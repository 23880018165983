import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs
  },
  state: {
    wizardBrandInfo: null,
    wizardTitle: null,
    wizardSubtitle: null,
    user: null
  },
  mutations: {
    setWizardBrandInfo(state, brand) {
      state.wizardBrandInfo = brand;
    },
    setWizardTitle(state, title) {
      state.wizardTitle = title;
    },
    setWizardSubtitle(state, subtitle) {
      state.wizardSubtitle = subtitle;
    },
    setUser(state, user) {
      state.user = user;
    }
  }
});
