import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      // redirect: "/dashboard",
      redirect: to => {
        // Redirect to Rentsure website (rather than showing a 404 page)
        window.location.href="https://rentsure.com.au";
      },
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/wizard",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "nb-rn",
              name: "nb-rn",
              component: () =>
                import("@/view/pages/wizard/newbusiness-renewal/NewBusinessRenewal.vue")
            },
            {
              path: "proposal-form",
              name: "proposal-form",
              component: () =>
                  import("@/view/pages/wizard/motor-proposal-form/MotorProposalForm.vue")
            },
            {
              path: "proposal-form-init",
              name: "proposal-form-init",
              component: () =>
                  import("@/view/pages/wizard/motor-proposal-form/MotorProposalFormInit.vue")
            },
            {
              path: "proposal-form-pdf",
              name: "proposal-form-pdf",
              component: () =>
                  import("@/view/pages/wizard/motor-proposal-form/MotorProposalFormPdf.vue")
            },
            {
              path: "fleet-upload",
              name: "fleet-upload",
              component: () =>
                  import("@/view/pages/wizard/fleet-upload/FleetUploadForm.vue")
            },
            {
              path: "fleet-upload-processing",
              name: "fleet-upload-processing",
              component: () =>
                  import("@/view/pages/wizard/fleet-upload/FleetUploadProcessing.vue")
            },
            {
              path: "nsw-sde-form",
              name: "nsw-sde-form",
              component: () =>
                  import("@/view/pages/wizard/nsw-stamp-duty-exemption-form/NSWStampDutyExemptionForm.vue")
            },
            {
              path: "contact-form",
              name: "contact-form",
              component: () =>
                  import("@/view/pages/wizard/contact-form/ContactForm.vue")
            }
          ]
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
