import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./core/services/store";
import ApiService from "./core/services/api.service";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;

// Vue 3rd party plugins
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import VueCurrencyFilter from "vue-currency-filter";
import VueCurrencyInput from "vue-currency-input";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import VueFilterDateParse from "@vuejs-community/vue-filter-date-parse";
import VueSignaturePad from "vue-signature-pad";
import * as VeeValidate from "vee-validate";
import { required, email, oneOf, max_value, min_value, max, alpha_num, regex, numeric } from 'vee-validate/dist/rules';

Vue.use(VueSignaturePad);

Vue.use(VeeValidate);
Vue.component('ValidationProvider', VeeValidate.ValidationProvider);
Vue.component('ValidationObserver', VeeValidate.ValidationObserver);

VeeValidate.extend('totalPercentage', value => {
  if (value === 100) {
    return true;
  }
  return 'Total percentage must be 100';
});

VeeValidate.extend('max_value', {
  ...max_value,
  params: ['max'],
  message: 'Maximum value allowed: {max}'
});

VeeValidate.extend('min_value', {
  ...min_value,
  params: ['min'],
  message: 'Minimum value allowed: {min}'
});

VeeValidate.extend('min_count', {
  ...min_value,
  params: ['min'],
  message: 'At least {min} entry is required'
});

VeeValidate.extend('max', {
  ...max,
  params: ['length'],
  message: 'Maximum {length} characters allowed'
});

VeeValidate.extend('email', {
  ...email,
  message: 'Invalid email address'
});

VeeValidate.extend('required', {
  ...required,
  message: 'This field is required'
});

VeeValidate.extend('required-action', {
  ...required,
  message: 'This action is required'
});

VeeValidate.extend('oneOf', {
  ...oneOf,
  message: 'Choose one'
});

VeeValidate.extend('checklist', {
  message: 'Check list item must be completed',
  validate: value => !! value
});

VeeValidate.extend('alpha_num', {
  ...alpha_num,
  message: 'This field can contain only alphabets and numbers'
});

VeeValidate.extend('numeric', {
  ...numeric,
  message: 'This field can contain only numbers'
});

VeeValidate.extend('regex', {
  ...regex,
  params: ['regex'],
  message: 'Invalid input format'
});


Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: { prefix: "$ " },
    allowNegative: false,
    distractionFree: false,
    precision: 2,
  },
});

Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: true,
});

Vue.use(VueFilterDateParse);
Vue.use(VueFilterDateFormat);

// API service init
ApiService.init();

Vue.prototype.$apiService = ApiService;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
