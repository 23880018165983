const TOKEN_KEY = "access_token";

export const getToken = () => {
  return window.sessionStorage.getItem(TOKEN_KEY);
};

export const saveToken = token => {
  window.sessionStorage.setItem(TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.sessionStorage.removeItem(TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
